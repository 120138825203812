<template>
   <div class="bg-white text-center border rounded-md">
      <div class="py-40 text-lg text-gray-400">
         Comming Soon...
      </div>
   </div>
</template>

<script>
export default {
   
}
</script>